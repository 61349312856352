import React from 'react';
import {
  Show,
  TopToolbar,
  ListButton,
  EditButton,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  RichTextField,
  FunctionField,
  ArrayField,
  Datagrid,
  UrlField,
} from 'react-admin';
import { Typography } from '@material-ui/core';

import { LayoutTitle, StatusIcon } from '../Shared/Layout';
import { ActionsTypes, PropTypes } from '../../tools/types';
import ShopDetails from '../Reconciliation/ShopDetails';

// Style
import './Bill.css';

// Utils
import requester from '../../tools/requester';
import config from '../../config';

class BillShow extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  componentDidMount() {
    this.fetchBillTransactions();
  }

  initialState = () => ({ loading: false })

  fetchBillTransactions = async () => {
    this.setState({ loading: true });
    const { id } = this.props;
    const test = config.services.backend.endpoint;
    try {
      const response = await requester(`${test}/bills/${id}/transactions`, { method: 'get' });
      this.setState({ formattedTransactions: response.data, error: null, loading: false });
    } catch (error) {
      this.setState({
        error: error.response && error.response.status === 500 ? error.response.data.toString() : error.message,
        loading: false,
      });
    }
  }

  renderPaymentDate = (record) => (
    <div className="payment-info">
      <StatusIcon source="paid" record={record} label="Payé" />
      <DateField source="paymentDate" record={record} showTime locales="fr-FR" />
    </div>
  );

  renderPaymentAmountPlatform = (record) => (this.renderPaymentCommon(record, 'valuePlatform'));

  renderPaymentAmount = (record) => (this.renderPaymentCommon(record, 'value'));

  renderPaymentPlatform = (record) => (this.renderPaymentCommon(record, 'totalDifference'));

  renderPaymentCommon = (record, propName) => (
    <TextField source="value" record={{ ...record, value: `${record[propName] || 0} ${record.currency || '€'}` }} />
  )

  /**
   * BillShow
   */
  render() {
    const {
      loading, formattedTransactions,
    } = this.state;
    return (
      <Show actions={<BillActions />} title={<LayoutTitle source="name" />} {...this.props}>
        <TabbedShowLayout>
          <Tab label="Résumé">
            <TextField source="id" />
            <TextField source="reference" label="Référence" />
            <TextField source="network.name" label="Network" />

            <DateField source="created" showTime locales="fr-FR" label="Date de création dans le BO" />
            <DateField source="billDate" showTime locales="fr-FR" label="Date de facturation" />

            <FunctionField label="Montant total facture BO" render={this.renderPaymentAmount} />

            <FunctionField label="Montant total facture Platform" render={this.renderPaymentAmountPlatform} />

            <FunctionField label="Somme des écarts BO" render={this.renderPaymentPlatform} />

            <FunctionField label="Date de paiement" render={this.renderPaymentDate} />

            <UrlField source="network.billHelpUrl" label="Aide réconciliation" target="_blank" />

            <RichTextField source="comment" label="Commentaire" />
          </Tab>
          <Tab label="Transactions">
            <div className="transaction-list" style={{ width: '100%', marginBottom: '10px' }}>
              {!loading && !!formattedTransactions && Object.values(formattedTransactions).map((shop) => (
                <ShopDetails
                  shop={shop}
                  key={shop.id}
                  disableCheckbox
                  currency="EUR"
                />
              ))}
            </div>
            {loading && (
              <Typography>Récupération des transactions...</Typography>
            )}
          </Tab>
          <Tab label="Ecarts">
            <ArrayField source="differences" addLabel={false}>
              <Datagrid>
                <FunctionField label="Valeur" render={this.renderPaymentAmount} />
                <TextField source="type" label="Type" />
                <TextField style={{ maxWidth: '400px', 'white-space': 'pre-wrap' }} component="pre" source="comment" label="Commentaire" />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    );
  }
}

BillShow.propTypes = {
  id: PropTypes.string,
};

function BillActions({ basePath, data }) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
</TopToolbar>
  );
}

BillActions.propTypes = ActionsTypes.Show;

export default BillShow;
