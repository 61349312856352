import * as Papa from 'papaparse';

export const transformAffilae = ({ billFile, conversionsFile }) => {
  const affiliateData = [];
  const conversionData = [];

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    let text;
    // tranform txt to csv file
    fileReader.onload = async (e) => {
      text = (e.target.result);
      const lines = text.trim().split('\n');

      let transformedCsv = 'ID;Règle;Date;Montant;Commissions_CPA;Commissions_CPL;Commissions_CPC\n';
      // Process each transaction and write to the CSV file
      for (let i = 0; i < lines.length; i += 7) {
        const transactionLine = lines.slice(i, i + 7);
        const transactionId = transactionLine[0];
        const rule = transactionLine[1];
        const date = transactionLine[2];
        const montant = transactionLine[3];
        const commissionsCpa = transactionLine[4];
        const commissionsCpl = transactionLine[5];
        const commissionsCpc = transactionLine[6];

        transformedCsv += `${transactionId};${rule};${date};${montant};${commissionsCpa};${commissionsCpl};${commissionsCpc}\n`;
      }
      Papa.parse(transformedCsv, {
        delimiter: ';',
        header: true,
        dynamicTyping: true,
        step: (result) => {
          affiliateData.push(result.data);
        },
        complete: () => {
          // Read Conversions csv file to find ID_affilae mapping from externalId and commissionCPA
          Papa.parse(conversionsFile[0], {
            header: true,
            dynamicTyping: true,
            step: (result) => {
              conversionData.push(result.data);
            },
            complete: () => {
              // Add the 'ID_affilae' column to the affiliateData
              const updatedAffiliateData = affiliateData.map((affiliateRecord) => {
                if (!affiliateRecord || !affiliateRecord.ID) return {};
                const commissionCPA = parseFloat(affiliateRecord?.Commissions_CPA?.replace('€', '').replace(',', '.'));
                // Find conversions that match the externalId
                // There might have several commissions matching the externalId
                const conversions = conversionData.filter(
                  (c) => c.externalId === affiliateRecord.ID,
                );
                let idMapped = 'Not found';
                conversions?.forEach((element) => {
                  Object.entries(element).forEach(([key, value]) => {
                    // In case of multiple conversions with the same external id find the one with matching commission value
                    if ((key.match(/contributor(\d){1,3}_commission/) || key.match(/contributor(\d){1,3}_partOfRevenue/)) && value === commissionCPA) {
                      idMapped = element.id;
                    }
                  });
                });
                return {
                  ...affiliateRecord,
                  ID_affilae: idMapped,
                };
              });

              // Write the result to the output CSV
              let outputCsv = `${Object.keys(updatedAffiliateData[0]).join(';')}\n`;
              updatedAffiliateData.forEach((record) => {
                if (record.ID_affilae !== 'Not Found') {
                  outputCsv += `${Object.values(record).join(';')}\n`;
                }
              });
              resolve(new Blob([outputCsv], { type: 'text/csv' }));
            },
          });
        },
      });
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
    fileReader.readAsText(billFile[0]);
  });
};
