import { Typography } from '@material-ui/core';
import { omit } from 'lodash';
import React from 'react';

import {
  Edit, TopToolbar, TabbedForm, FormTab, BooleanInput,
  DateInput, TextInput, required,
  ShowButton, ListButton, Toolbar, SaveButton, DeleteButton, usePermissions,
  ArrayInput, SimpleFormIterator, NumberInput, SelectInput, FileInput, FileField,
  UrlField,
} from 'react-admin';
import { ActionsTypes, PropTypes } from '../../tools/types';
import { LayoutTitle, SectionTitle } from '../Shared/Layout';

import './Bill.css';

/**
 * BillEdit
 */

function PaidInput({ record = {} }) {
  return <BooleanInput label="Payé" source="paid" disabled={record.paid} />;
}

PaidInput.propTypes = {
  record: PropTypes.object,
};

function ImportInput({ record = {}, ...props }) {
  if (record.paid) {
    return <Typography>Import désactivé pour les factures déjà payées.</Typography>;
  }

  return (
    <FileInput disabled={record.paid} {...props}>
      <FileField source="src" title="title" />
    </FileInput>
  );
}

ImportInput.propTypes = {
  record: PropTypes.object,
};

function BillEdit(props) {
  const ownProps = { ...omit(props, ['hasList', 'hasShow', 'hasEdit', 'hasCreate']) };
  const { permissions } = usePermissions();

  return (
    <Edit title={<LayoutTitle />} actions={<BillActions />} {...ownProps}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar permissions={permissions ? permissions.shops : {}} />}>
        <FormTab variant="standard" label="Résumé">
          <TextInput source="id" />
          <TextInput source="reference" label="Référence" />
          <TextInput disabled source="network.name" label="Network" />

          <DateInput disabled source="created" locales="fr-FR" label="Date de création dans le BO" />
          <DateInput source="billDate" locales="fr-FR" label="Date de facturation" validate={required()} />

          <TextInput disabled source="value" label="Montant total facture BO" />
          <NumberInput source="valuePlatform" label="Montant total facture platform" />

          <SectionTitle label="Paiement" />
          <PaidInput />
          <DateInput source="paymentDate" locales="fr-FR" label="Date de paiement" />
          <UrlField source="network.billHelpUrl" label="Aide réconciliation" target="_blank" />

          <SectionTitle label="" />
          <TextInput multiline source="comment" label="Commentaire" />
          <ImportInput
            source="billFile"
            label="Réconciliation automatique"
            placeholder="Importer une nouvelle facture"
          />
        </FormTab>
        <FormTab variant="standard" label="Ecarts">
          <ArrayInput fullWidth source="differences" label="Ecarts">
            <SimpleFormIterator>
              <NumberInput source="value" label="Valeur" validate={required()} />
              <SelectInput
                source="type"
                label="Type"
                validate={required()}
                choices={[
                  { id: 'Arrondis', name: 'Arrondis' },
                  { id: 'Browser Adjustment', name: 'Browser Adjustment' },
                  { id: 'Régul/Ajout', name: 'Régul/Ajout' },
                  { id: 'Primes/Challenge', name: 'Primes/Challenge' },
                  { id: 'Changement de devise', name: 'Changement de devise' },
                  { id: 'Frais de gestion', name: 'Frais de gestion' },
                  { id: 'Décommissionnement', name: 'Décommissionnement' },
                  { id: 'Attente de réaffectation', name: 'Attente de réaffectation' },
                  { id: 'Manquants', name: 'Manquants' },
                  { id: 'Autre', name: 'Autre' },
                ]}
              />

              <TextInput options={{ multiline: true }} source="comment" label="Commentaire" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

function BillActions({ basePath, data }) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
</TopToolbar>
  );
}
BillActions.propTypes = ActionsTypes.Edit;

function CustomToolbar(props) {
  return (
<Toolbar {...props}>
    <SaveButton {...props} />
    {props.permissions.delete && !props.record.paid && <DeleteButton {...props} />}
</Toolbar>
  );
}
CustomToolbar.propTypes = {
  permissions: PropTypes.object,
  record: PropTypes.object,
};

export default BillEdit;
